<style lang="scss">
    .header-container{
      display: flex;
      justify-content: start;
    }

    .header-logo {
        float: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        img {
            display: block;
        }
    }
    @media (width <= 450px){
      .header-container{
        justify-content: center;
      }
    }
</style>

<template>
  <div class="header-container">
    <a class="header-logo" href="/">
      <img :src="src">
    </a>
  </div>
</template>

<script>
    import Bus from '../bus';

    export default {
        props: ['code'],
        data() {
            return {
                src: `${this.code}/images/header_logo.png`
            };
        },
        created() {
            Bus.$on('refresh.logo', this.refreshImage);
        },
        methods: {
            refreshImage() {
                this.src = `${this.src}?refresh=${new Date().getTime()}`;
            }
        }
    };
</script>
