<style lang="scss">
    .alert-danger {
        color: #FF5722 !important;
        border-color: #FE5621 !important;
    }
    .alert {
        position: relative;
        padding: .75rem 1.25rem;
        margin-bottom: 1rem;
        border: 1px solid transparent;
        border-radius: .25rem;
    }
    .track-manager {
        display: flex;
        flex-direction: column;
        gap: 40px;
        * {
            box-sizing: border-box;
        }
    }

    .track-manager__add {
        position: relative;
        float: left;
    }

    .track-manager__create {
        position: relative;
        float: left;
    }

    .track-manager__list {
        position: relative;
        margin-top: 1px;
    }

    .track-manager__buttons {
        text-align: center;

        > * {
            margin: 0 10px;
        }
    }

    .track-manager__button {
        display: inline-block;
        border-radius: 3px;
        padding: 10px 15px;
        font-weight: 600;
    }
</style>

<template>
    <div class="track-manager">
        
        <div class="track-manager__create">
            <h2>{{messages.yourAlbum}}</h2>
            <div class="track-manager__list">
                <transition-group :name="listTransition" ref="sortable" tag="ul">
                    <track-item v-for="(track, index) in album.tracks"
                                :track="track"
                                :index="index"
                                :key="track.id"
                                :types="trackTypes"
                                :disabledactiontracks="disabledactiontracks"
                                @remove="removeTrack"
                    />
                </transition-group>
                <loading-overlay v-if="isListLoading"></loading-overlay>
            </div>
        </div>
        <div class="track-manager__add">
            <template v-if="!disabledactiontracks">
                <v-alert
                    text
                    v-bind:class="'mb-3 alert alert-danger error'"
                    color="orange lighten-5"
                    v-model="messages.disabledActions"
                    :dismissible="false"
                >
                  {{messages.disabledActions}}
                </v-alert>
            </template>
            <template v-else>
                <h2>{{messages.addMore}}</h2>
                <transition name="fade" mode="out-in">
                    <track-catalog v-if="isCatalogOpen" :album="album"
                                   @add="addTrack"
                                   @close="closeCatalog"
                                   :disabledactiontracks="disabledactiontracks"
                    />
                    <div class="track-manager__buttons" v-else>
                        <input-file :text="messages.fromComputer"
                                    :multiple="true"
                                    @change="upload($event.target.files)"
                                    class="track-manager__button input-button"
                                    ref="inputFile"
                        >
                        </input-file>
                        <input-button
                            :text="messages.fromCatalog"
                            @click="openCatalog"
                        >
                        </input-button>
                    </div>
                </transition>
            </template>
        </div>
        <loading-overlay v-if="isGlobalLoading"></loading-overlay>
    </div>
</template>

<script>
    import Sortable from 'sortablejs';
    import Bus from '../bus';
    import { TRACK_TYPE } from '../constants/constants';
    import { _T } from '../globals';
    import InputButton from './form-elements/input-button.vue';
    import InputFile from './input-file.vue';
    import LoadingOverlay from './loading-overlay.vue';
    import TrackCatalog from './track-catalog.vue';
    import TrackItem from './track-item.vue';

    export default {
        props: ['album', 'disabledactiontracks'],
        components: {
            InputButton,
            InputFile,
            LoadingOverlay,
            TrackCatalog,
            TrackItem
        },
        data() {
            return {
                isCatalogOpen: false,
                isListLoading: false,
                isGlobalLoading: false,
                listTransition: 'fade',
                trackTypes: [
                    TRACK_TYPE.EDITABLE,
                    TRACK_TYPE.REMOVABLE,
                    TRACK_TYPE.SORTABLE,
                    TRACK_TYPE.UPLOADABLE
                ],
                messages: {
                    addMore: _T('add_more_tracks'),
                    yourAlbum: _T('track_list'),
                    fromCatalog: _T('from_catalog'),
                    fromComputer: _T('from_computer'),
                    disabledActions: _T('disabled_action_tracks')
                },
                sortable: null
            };
        },
        mounted() {
            if (this.disabledactiontracks) {
                this.sortable = Sortable.create(this.$refs.sortable.$el, {
                    animation: 150,
                    ghostClass: 'track-item--ghost',
                    onEnd: this.sorted
                });
            }
            this.album.getTracks()
                .then(() => {
                    this.listTransition = 'shake-in-fade-out';
                });

            this.album.onTracksAdded = () => {
                this.isGlobalLoading = false;
            };

            this.album.onTracksUploadError = () => {
                this.isGlobalLoading = false;
            };
        },
        methods: {
            upload(files) {
                window.clarity('event', 'add_track_from_computer');
                this.isGlobalLoading = true;
                this.album.uploadTracks(files);
                this.$refs.inputFile.clean();
            },
            sorted(e) {
                this.listTransition = null;
                this.isListLoading = true;

                this.album.sortTracks(e.oldIndex, e.newIndex)
                    .then(() => {
                        Bus.$emit('album.updated');
                        this.isListLoading = false;
                        this.listTransition = 'shake-in-fade-out';
                    })
                    .catch(() => {
                        this.skipSort(e);
                        this.isListLoading = false;
                        this.listTransition = 'shake-in-fade-out';
                    });
            },
            skipSort(e) {
                const list = this.sortable.toArray();
                const oldItem = list[e.newIndex];

                list.splice(e.newIndex, 1);
                list.splice(e.oldIndex, 0, oldItem);

                this.sortable.sort(list);
            },
            addTrack(track) {
                this.isListLoading = true;

                this.album.addTrack(track)
                    .then(() => {
                        Bus.$emit('album.updated');
                        this.isListLoading = false;
                    });
            },
            removeTrack(index) {
                this.isListLoading = true;

                this.album.removeTrack(index)
                    .then(() => {
                        Bus.$emit('album.updated');
                        this.isListLoading = false;
                    }).catch(() => {
                        this.isListLoading = false;
                    });
            },
            openCatalog() {
                window.clarity('event', 'add_track_from_catalog');
                this.isCatalogOpen = true;
            },
            closeCatalog() {
                this.isCatalogOpen = false;
            }
        }
    };
</script>
