<style lang="scss">
    .line-chart {
        position: relative;
        float: left;
        margin-bottom: 10px;
        width: 100%;
        box-sizing: border-box;
    }
    .line-chart * {
        box-sizing: border-box;
    }
    .line-chart__loader {
        position: absolute;
        display: inline-block;
        left: 50%;
        top: 50%;
        margin-top: -16px;
        margin-left: -16px;
        width: 32px;
        height: 32px;
        animation: spinner 1s infinite steps(8);
    }
    .line-chart__wrapper {
        position: relative;
    }
    .line-chart__alert {
        margin-bottom: 10px;
        text-align: center;
    }

</style>

<template>
    <div class="line-chart">
        <h1>{{ messages.title }}: {{getTotal}}{{currency.symbol}}</h1>
        <div class="line-chart__wrapper">
            <alert :message="messages.empty" v-if="isEmpty && !isError" class="line-chart__alert"></alert>
            <alert :message="messages.error" v-else-if="isEmpty && isError" class="line-chart__alert"></alert>
            <spinner-icon viewBox="0 0 32 32" class="line-chart__loader" v-if="isLoading"></spinner-icon>
            <div v-show="!isEmpty">
                <svg ref="graph"></svg>
                <chart-legend :items="data.nested" :left="chart.margin.left"></chart-legend>
                <chart-tooltip :tooltip="tooltip"></chart-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
    import Alert from './alert.vue';
    import ChartLegend from './chart-legend.vue';
    import ChartTooltip from './chart-tooltip.vue';
    import SpinnerIcon from '../../svg/spinner.svg';

    import Message from '../models/message';

    import { MESSAGE_TYPE } from '../constants/constants';
    import { _T } from '../globals';

    export default {
        props: ['chart', 'data', 'currency', 'isError'],
        components: {
            Alert,
            ChartLegend,
            ChartTooltip,
            SpinnerIcon
        },
        data() {
            return {
                tooltip: {},
                messages: {
                    title: _T('confirmed_sales_on_period'),
                    empty: new Message(_T('Warning: No plot_data retrieved'), MESSAGE_TYPE.ERROR),
                    error: new Message(_T('Graph external error'), MESSAGE_TYPE.ERROR)
                }
            };
        },
        mounted() {
            this.chart.onMouseOver = (tick) => {
                this.tooltip = tick;
            };

            this.chart.onMouseOut = () => {
                this.tooltip = {};
            };

            this.chart.build(this.$refs.graph);
        },
        computed: {
            isLoading() {
                return Object.keys(this.data).length === 0;
            },
            isEmpty() {
                return this.data.parsed !== undefined && this.data.parsed.length === 0;
            },
            getTotal() {
                return this.data.total === undefined || this.isEmpty ? '...' : this.data.total;
            }
        }
    };
</script>
