<template>
  <div
    :for="fieldId"
    :class="error_code"
    :optional="isOptional"
  >
    <label class="input__label" v-if="text">
        {{ translate(text) }}
        <span
            v-if="isOptional"
            class="input__label--optional"
        >
            {{ optional }}
        </span>
    </label>
    <slot></slot>
</div>
</template>

<script>

import {_T} from '../../globals';

export default {
    name: 'InputText',
    props: {
        fieldId: {
            type: String,
            default: ''
        },
        instruction: {
            type: String,
            default: ''
        },
        isOptional: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: ''
        },
        optional: {
            type: String,
            default: '(optional)'
        },
        error_code: {
            type: String,
            default: ''
        }
    },

    methods: {
        translate(string) {
            return _T(string);
        }
    }
}
</script>
